/**
 * @input string utc date
 */
export const utcToString = (date: string) => date.replace(/T/, ' ').replace(/\..+/, '');

export const getCurrencyView = (amount: number, currency?: string): string => {
  if (amount === undefined) {
    return '';
  }
  const dollar = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2, // this means we will always show 2 decimal places even if .00
  });
  // the currency amount is stored in minor format (x100), so we need to divide by 100 and format
  const formattedAmount = dollar.format(Math.round(amount) / 100);
  return currency === undefined ? formattedAmount : `${currency} ${formattedAmount}`;
};
