import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// RowIfNotEmpty will display a row if the value is defined
export default function RowIfNotEmpty(heading: string, value?: string): JSX.Element {
  if (value !== undefined && value != '') {
    return (
      <TableRow>
        <TableCell sx={{ overflowWrap: 'break-word' }}>{heading}</TableCell>
        <TableCell sx={{ overflowWrap: 'break-word' }} align="right">
          {value}
        </TableCell>
      </TableRow>
    );
  } else {
    return <></>;
  }
}
