import { DialogContent, Table, TableBody, TableRow, TableCell, DialogTitle } from '@mui/material';

import { getCurrencyView } from '../../../utils/format';
import { Recipient, Sender, SenderAccount } from '../MandatesTable';
import RowIfNotEmpty from './Row';
import DialogWrapper from './Wrapper';

type Props = {
  open: boolean;
  handleClose: () => void;
  payment: Payment;
};

type Fee = {
  amount: number;
  currency: string;
  paid_by: 'SENDER' | 'RECIPIENT';
  paid_by_account_id: string;
};

export type Payment = {
  payment_id: string;
  amount: number;
  amount_total_with_surcharge: number;
  surcharge_amount: number;
  currency: string;
  type: string;
  updated_at: string;
  status: string;
  payment_details: {
    description: string;
    mandate_id: string;
    transaction_reference_id: string;
  };
  recipient: Recipient;
  sender: Sender;
  sender_account: SenderAccount;
  created_at: string;
  payment_method_id?: string;
  payment_method?: {
    card: {
      card_details: {
        brand: string;
        last4: string;
      };
    };
  };
  metadata?: Record<string, string>;
  fees: Fee[];
};

export function PaymentDetails({ open, handleClose, payment }: Props) {
  return (
    <DialogWrapper
      id=""
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      title="Payment Details"
      open={open}
    >
      <DialogContent dividers>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell align="right">{getCurrencyView(payment.amount, payment.currency)}</TableCell>
            </TableRow>
            {RowIfNotEmpty('Surcharge Amount', getCurrencyView(payment.surcharge_amount, payment.currency))}
            <TableRow>
              <TableCell>Total Amount with Surcharge</TableCell>
              <TableCell align="right">
                {getCurrencyView(payment.amount_total_with_surcharge, payment.currency)}
              </TableCell>
            </TableRow>
            {renderFees(payment)}
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">{payment.payment_details.description}</TableCell>
            </TableRow>
            {RowIfNotEmpty('Payment Method ID', payment.payment_method_id)}
            {RowIfNotEmpty('Mandate ID', payment.payment_details.mandate_id)}
            {RowIfNotEmpty('Card brand', payment.payment_method?.card.card_details.brand)}
            {RowIfNotEmpty('Card number (last 4)', payment.payment_method?.card.card_details.last4)}
            <TableRow>
              <TableCell>Transaction Reference ID</TableCell>
              <TableCell align="right">{payment.payment_details.transaction_reference_id}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {renderMetadata(payment.metadata)}
      </DialogContent>
    </DialogWrapper>
  );
}

const renderMetadata = (metadata?: Record<string, string>): JSX.Element => {
  if (metadata === undefined) {
    return <></>;
  }

  const rows = [];

  for (const key of Object.keys(metadata)) {
    rows.push(RowIfNotEmpty(key, metadata[key]));
  }

  return (
    <>
      <DialogTitle sx={{ textAlign: 'center' }}>Metadata</DialogTitle>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableBody>{rows}</TableBody>
      </Table>
    </>
  );
};

const renderFees = (payment: Payment): JSX.Element => {
  const feeRows = [];

  for (const fee of payment.fees) {
    if (fee.paid_by === 'SENDER') {
      feeRows.push(RowIfNotEmpty('Fee Surcharge', `${fee.currency} ${getCurrencyView(fee.amount)}`));
    } else {
      feeRows.push(RowIfNotEmpty('Payment Fee', `${fee.currency} ${getCurrencyView(fee.amount)}`));
    }
  }

  return <>{feeRows}</>;
};
